
import Vue, { PropType } from 'vue';
import { groupBy } from 'lodash';

// @ts-ignore
import NmdcSchema from 'nmdc-schema/nmdc_schema/nmdc_materialized_patterns.yaml';

import { fieldDisplayName } from '@/util';
import * as encoding from '@/encoding';
import { Condition, entityType } from '@/data/api';

const groupOrders = [
  'study',
  'function',
  'sample',
  'gold ecosystems',
  'mixs environmental triad',
  'data generation',
];

export interface SearchFacet {
  field: string;
  table: entityType;
  group?: string;
}

type KeyedFieldData = encoding.FieldsData & SearchFacet & { key: string; };

export default Vue.extend({
  props: {
    conditions: {
      type: Array as PropType<Condition[]>,
      required: true,
    },
    fields: {
      type: Array as PropType<SearchFacet[]>,
      required: true,
    },
    filterText: {
      type: String,
      required: true,
    },
    facetValues: {
      type: Array as PropType<Condition[]>,
      required: true,
    },
  },
  data() {
    return {
      menuState: {} as Record<string, boolean>,
    };
  },
  computed: {
    privatefilteredFields(): SearchFacet[] {
      const { filterText } = this;
      if (filterText) {
        return this.fields.filter(({ field, table }) => {
          const lower = filterText.toLowerCase();
          return (
            field.toLowerCase().indexOf(lower) >= 0
            || fieldDisplayName(field, table).toLowerCase().indexOf(lower) >= 0
          );
        });
      }
      return this.fields;
    },
    groupedFields(): [string, KeyedFieldData[]][] {
      const fieldsWithMeta = this.privatefilteredFields
        .map((sf) => ({
          key: `${sf.table}_${sf.field}`,
          ...sf,
          ...encoding.getField(sf.field, sf.table),
        }))
        .filter(({ hideFacet }) => !hideFacet)
        .sort(((a, b) => (a.sortKey || 0) - (b.sortKey || 0)));
      return Object.entries(groupBy(fieldsWithMeta, 'group'))
        .sort(([a], [b]) => {
          const ai = groupOrders.indexOf(a.toLowerCase());
          const bi = groupOrders.indexOf(b.toLowerCase());
          return ai - bi;
        });
    },
    goldDescription() {
      // @ts-ignore
      const schema = NmdcSchema.slots.gold_path_field;
      return schema.annotations?.tooltip?.value || '';
    },
  },
  methods: {
    fieldDisplayName,
    toggleMenu(category: string, value: boolean): void {
      Vue.set(this.menuState, category, value);
    },
    hasActiveConditions(category: string): boolean {
      return this.conditions.some((cond) => `${cond.table}_${cond.field}` === category);
    },
    tableName(table: string): string {
      if (table === 'study') {
        return 'Study';
      }
      if (table === 'biosample') {
        return 'Biosample';
      }
      if (table === 'omics_processing') {
        return 'Omics Processing';
      }
      return '';
    },
  },
});
